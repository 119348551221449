const landmarklifestyleDev={
    misc: {
        "isUnbxdControl": function() {
            var cookies = document.cookie.split(';');
            for (let cookie of cookies) {
                const [cookieName, cookieValue] = cookie.trim().split('=');
                if (cookieName == "isUnbxdControl") {
                    return cookieValue;
                }
            }
            // If the cookie is not found, return null
            return null;
        }
    },
    search: {
        inputBox: "form[name='header_search'] input#js-site-search-input",
        urlParam: "q",
    },
    catPage: {
        catPageSelector: ".main-page-c [data-pagetype='category']",
        urlIdentifier: "/in/en/c/",
        getCategoryPathCB: function(e) {
            return e.replace("/in/en/c/", "allCategories:")
        },
        delay: 1500
    },
    productClickGrid: {
        productElemWrapper: ".MuiGrid-container .MuiGrid-item .product,.ReactVirtualized__Grid__innerScrollContainer .product",
        productPidAttr: "id",
        getPidFromCB: function(e) {
            return e.replace(/^product-|-/g, "")
        },
        source: "PRODUCT_CLICK_GRID"
    },
    addToCartFromPDP: {
        addToCartBtnSelector: "#notify-quantity button.MuiButton-contained,#details-bottom-btn button",
        pidSelector: "#main-part [data-unbxdpid]",
        productPidAttr: "data-unbxdpid",
        getPidFromCB: function(e) {
            return e.replace(/^product-|-/g, "")
        },
        getQtyFromCB: function (qtyString) {
          return "1";
        },
        source: "ADD_TO_CART_PDP"
    },
    addToCartFromCartPage: {
        cartItemWrapper: ".MuiGrid-item #basketItemsSection #basketItem,.MuiDialog-container.MuiDialog-scrollPaper, .MuiBox-root.jss130.jss129",
        pidSelector: ".MuiDialog-container.MuiDialog-scrollPaper .imageContainer img",
        productPidAttr: "src",
        getPidFromCB: function(e) {
            var t = e.lastIndexOf("/");
            if (t !== -1) {
                var substr = e.substring(t + 1).split("_")[0];
                var pid = substr.match(/\d+/)[0];
                var suffix = substr.replace(/\d+/g, "").replace("-", "");
                return pid + suffix.replace(/-/g, "");
            }
            return "";
        },
        qtySelector: ".MuiDialog-container .MuiBox-root.active .MuiBox-root",
        qtyPlusSelector: ".MuiDialog-container .MuiButtonBase-root.MuiButton-root",
        source: "ADD_TO_CART_FROM_CART_PAGE"
    },
    pageView: {
        events: {
            home: {
                uniqueSelectors: {
                    selectors: [".main-page-home"]
                },
                urlIdentifiers: {
                    urls: ["https://uat2.lifestylestores.com/in/en/"],
                    exactMatch: !0
                }
            },
            search: {
                uniqueSelectors: {
                    selectors: [".header-page-search"]
                },
                urlIdentifiers: {
                    urls: ["https://uat2.lifestylestores.com/in/en/search?q="],
                    exactMatch: false,
                },
                misc: {
                    fireSearch: function () {
                        var prevSearchQuery = sessionStorage.getItem('prevSearchQuery');
                        var queryString = window.location.search;
                        var searchParams = new URLSearchParams(queryString);
                        var qParam = searchParams.get('q');

                        if (qParam === prevSearchQuery) {
                            return null;
                        } else {
                            Unbxd.track("search", { "query": qParam });
                            return "search"
                        }
                    }
                }
            },
            category: {
                uniqueSelectors: {
                    selectors: [".main-page-c"]
                },
                urlIdentifiers: {
                    urls: ["https://uat2.lifestylestores.com/in/en/c/"],
                    exactMatch: false,
                }
            },
            productDisplay: {
                uniqueSelectors: {
                    selectors: [".main-page-p"]
                }
            },
            cart: {
                uniqueSelectors: {
                    selectors: [".main-page-cart"]
                }
            }
        }
    }
}



export default landmarklifestyleDev;